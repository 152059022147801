import React from "react";
import "./whoWeAre.css";
import pr2 from "../../img/Feature 1.png";
import pr3 from "../../img/Feature 2.png";
import pr4 from "../../img/Feature 3.png";
// import pr4 from "../../img/wikiworks logo 230725-01 2.png";

function whoWeAre({openModal}) {
  return (
    <div className="whoWeAreMain">
      <div className="who-we-are-main-head">
        Features
      </div>
      <div id="WhoWeAre" className="whoWeAreUp">
        <div className="whoWeAreRight">
          <h1 className="whoWeAreHeader">Seamless onboarding</h1>

          <div className="whoWeAreText">
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              Effortlessly onboard workers with Aadhaar-based verification.
            </p>
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              Customizable approval workflows.
            </p>
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              Seamless contractor agency integration/onboarding{" "}
            </p>
          </div>
        </div>

        <div className="whoWeAreLeft">
          <img src={pr2} alt="pr2" />
        </div>
      </div>

      <div id="WhoWeAre" className="whoWeAreDown">
        <div className="whoWeAreRight">
          <h1 className="whoWeAreHeader">Compliance reconciliation</h1>

          <div className="whoWeAreText">
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              Ensure compliance with single click PF/ESIC compliance
              reconciliation mechanism{" "}
            </p>
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              Proactive alerts and notifications{" "}
            </p>
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              Configurable Reports and Dashboards for more insights{" "}
            </p>
          </div>
        </div>

        <div className="whoWeAreLeft">
          <img src={pr3} alt="pr2" />
        </div>
      </div>

      <div id="WhoWeAre" className="whoWeAreUp">
        <div className="whoWeAreRight">
          <h1 className="whoWeAreHeader">Real time background checks</h1>

          <div className="whoWeAreText">
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              Online background check, integrated with databases of criminal
              records.{" "}
            </p>
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              Check criminal records with the risk level and offence category{" "}
            </p>
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              Know the status of the case from court{" "}
            </p>
          </div>
        </div>

        <div className="whoWeAreLeft">
          <img src={pr4} alt="pr2" />
        </div>
      </div>
      <div className="bottom-who">
        <div className="who-we-are-sub-header">
        Discover more features!
        </div>
              <button onClick={openModal} className="award-button-w">Book Demo</button>
      </div>
    </div>
  );
}

export default whoWeAre;
