// // BookDemoModal.js
// import React from 'react';
// import Modal from 'react-modal';
// import './reactModal.css';

// const BookDemoModal = ({ isOpen, onRequestClose }) => {
//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       className="book-demo-modal"
//       overlayClassName="book-demo-overlay"
//       ariaHideApp={false}
//     >
//       <div className="modal-header">
//         <h2>Book Demo</h2>
//         <button onClick={onRequestClose} className="close-button">×</button>
//       </div>
//       <form className="modal-form">
//         <label>
//           Name
//           <input type="text" name="name" />
//         </label>
//         <label>
//           Organization Mail Id
//           <input type="email" name="email" />
//         </label>
//         <label>
//           Date
//           <input type="date" name="date" placeholder='' />
//         </label>
//         <label>
//           Time
//           <input type="time" name="time" placeholder='' />
//         </label>
//         <button type="submit" className="submit-button">Book Demo</button>
//       </form>
//     </Modal>
//   );
// };

// export default BookDemoModal;

// BookDemoModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import './reactModal.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BookDemoModal = ({ isOpen, onRequestClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [emailError, setEmailError] = useState('');
  const [formError, setFormError] = useState('');

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    let isValid = true;
    setFormError('');
    if (!name || !email || !date || !time) {
      setFormError('All fields are required.');
      isValid = false;
    }
    if (!isValidEmail(email)) {
      setEmailError('Invalid email format');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!isValid) {
      return;
    }

    const payload = {
      name: name,
      email: email,
      date: date,
      time: time,
      from: "Wikiworks Demo Booking",
      to: "sales@wikiworks.com",
    };

    try {
      const response = await fetch("https://demo.wikiworks.in/api/ampliworks/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      console.log("Response data: ", data);

      if (response.ok) {
        toast.success("Demo booked successfully! We will contact you soon.");
        // Clear the form fields
        setName('');
        setEmail('');
        setDate('');
        setTime('');
        onRequestClose(); // Close the modal on success
      } else {
        toast.error("Failed to book demo. Please try again later.");
      }
    } catch (error) {
      console.error("Error: ", error);
      toast.error("An error occurred while booking the demo.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="book-demo-modal"
      overlayClassName="book-demo-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h2>Book Demo</h2>
        <button onClick={onRequestClose} className="close-button">×</button>
      </div>
      <form className="modal-form" onSubmit={handleSubmit}>
        <label>
          Name
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          Organization Mail Id
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <p className="errorText">{emailError}</p>}
        </label>
        <label>
          Date
          <input
            type="date"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </label>
        <label>
          Time
          <input
            type="time"
            name="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </label>
        {formError && <p className="errorText">{formError}</p>}
        <button type="submit" className="submit-button">Book Demo</button>
      </form>
      <ToastContainer position="top-left" style={{ marginTop: "20px" }} />
    </Modal>
  );
};

export default BookDemoModal;
