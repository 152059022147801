import HcmData from "../hcm/hcmData";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import img1 from "../../../img/compliances.jpg";
import "./privacyPolicy.css";

export default function PrivacyPolicy() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <div className="mainContPriv">
        <div className="segregateContainer-p">
          <div className="leftContent-p">
            <HcmData
              scrollTo={solutionsAndServicesRef}
              onClickButton={handleGoBack}
              isButton="Go Back"
              heading="PRIVACY POLICY
        "
              text="WIKIWORKS TECHNOLOGIES PRIVATE LIMITED (“VYN”, “WikiWorks”, we, us and terms of similar
            meaning) owns and operates the sites www.wikiworks.in and www.vyn.wikiworks.in
            "
            />

            <HcmData
              text="By accessing the above websites and related pages, you are agreeing to accept and comply with the
terms and conditions of use as stated in Terms of Service. The following statement describes the
privacy practices for WikiWorks. We respect individual privacy and strive to collect and use personal
information in a manner consistent with the applicable laws. Some information provided to us by
individuals, clients, customers, contractors and other third parties might be considered private or
personal. Without these details, we would not be able to carry on our business and provide our
services to you. We will only collect such personal information if it is necessary for one of our functions
or activities. This Online Privacy Policy covers use of the aforesaid sites. All information disclosed by
visitors is subject to this Privacy Policy. With respect to data received through its websites, WikiWorks
will comply with the following: "
            />
            <HcmData text="1. TYPES AND USES OF INFORMATION " />
            <HcmData
              text="a. WikiWorks gathers personal information from site visitors and through their VYN application from
              time to time in order to enhance existing offerings or provide additional services. The company gathers
              only the information essential for understanding visitors in such a way as to provide better products
              and related services. This may include contact information such as name, email address, telephone
              number and company name, and perhaps other data. "
            />
            <HcmData
              text="b. WikiWorks also may monitor customer traffic patterns and site usage to help refine and improve
              the design and layout of the website as well as the user experience. As a result, WikiWorks
              automatically tracks certain information about site visitors. This information includes the users’
              browser type, most recent URL and the next URL that the user visits, regardless of whether these URLs
              are on the WikiWorks website or not. WikiWorks may also track visitors’ IP addresses. WikiWorks uses
              this information to, among other things, identify broad demographic trends that may be used to
              provide information tailored to users’ interests. "
            />
            <HcmData
              text="c. Any personal information given to us through our website is used exclusively by WikiWorks unless
              otherwise noted on the site. WikiWorks does not make this information available to third parties who
              trade or rent information for direct marketing purposes. "
            />
            <HcmData
              text="d. WikiWorks does not collect e-mail addresses without the site visitor voluntarily providing us with
              this information. WikiWorks will periodically use that contact information to send visitors e-mail or
              other communications for sales, service, support and marketing purposes. Visitors who do not wish
              to receive marketing e-mail from WikiWorks in the future may opt out of receiving these
              communications by following the instructions included in each communication. "
            />

            <HcmData text="2. LINKS " />
            <HcmData
              text="Our web site may contain links to other web sites and those third-party web sites may collect personal
            information about you. We are not responsible for the privacy practices of other businesses or the
            content of web sites that are linked to our web site. WikiWorks encourages users to be aware when
            they leave the site and to read the privacy statements of each and every web site that collects
            personally identifiable information. "
            />
            <HcmData text="3. SECURITY OF INFORMATION " />
            <HcmData
              text="a) WikiWorks endeavours to maintain appropriate safeguards and adequate physical, procedural and
            technical security with respect to our offices and information storage facilities so as to prevent any
            unauthorized access, disclosure, copying, use, or modification of personal information. Safeguards
            include physical protection by the use of locked cabinets and technological measures by way of secure
            access and encryption. WikiWorks employees are authorized to access personal information based
            only on their need to deal with the information for the reason(s) for which it was obtained. Safeguards
            are in place to ensure that the information is not disclosed or shared more widely than is necessary to
            achieve the purpose for which it was gathered. We also take measures to ensure the integrity of this
            information is maintained and to prevent its being lost or destroyed. "
            />
            <HcmData
              text="b) To ensure the integrity and privacy of the personal information you pass to us via the Internet for
            official purpose, WikiWorks has undertaken necessary audits and obtained certification, with the
            strongest level of encryption. All information collected within a secure page is encrypted while being
            transmitted to WikiWorks’ secure server. The server is protected by a firewall that is regularly updated
            when new patches and fixes are released. "
            />
            <HcmData text="4. NOTIFICATION OF PRIVACY POLICY CHANGES/CHOICE" />
            <HcmData
              text="WikiWorks reserves the right to change, modify, add, or remove portions of this Policy at any time. All
            changes will be posted on this page from time to time to inform our users what information we collect,
            how we use it, and under what circumstances, if any, we may disclose it. We may e-mail periodic
            reminders of our notices and conditions, unless you have instructed us not to. Unless stated otherwise,
            our current privacy notice applies to all information that we have about you and your account. We
            will not materially change our policies and practices to make them less protective of personal
            information collected in the past without the consent of affected users. "
            />
            <HcmData text="5. LEGAL DISCLAIMER " />
            <HcmData
              text="WikiWorks may disclose Personal Information when required by law or in the good faith belief that
            such action is necessary in order to conform to the edicts of the law, comply with legal mandates,
            enforce the terms of use of WikiWorks’ website, enforce agreement between WikiWorks and its
            customers or to protect the rights, property, or personal safety of WikiWorks, its users and the public. "
            />
            <HcmData text="6. CONTACTING WIKIWORKS" />
            <HcmData
              text="If you have any questions about this privacy policy, the practices of this site, or your dealings with this
            site, please send correspondence to sales@wikiworks.in"
            />
          </div>
          {/* <div className="imageRight">
          <img src={img1} alt="" />
        </div> */}
        </div>
        {/* <button onClick={handleGoBack} className="homeIconCont">
        <h3 style={{ marginTop: "-0rem" }}>Go Back</h3>
      </button> */}
        {/* <div >
          <i
            style={{
              fontSize: "30px",
              color: "#0077B6",
              marginLeft: "4rem",
              marginBottom: "3rem",
              cursor: "pointer",
            }}
            class="fa-solid fa-circle-arrow-left"
          ></i>
        </div> */}
        <button
          onClick={handleGoBack}
          style={{ width: "40vw", alignSelf: "center", marginBottom: "5rem" }}
          className="learnButton"
        >
          <div className="button-text">I have read the privacy policy</div>
        </button>
      </div>
      {/* <Contact_us /> */}
      <Footer />
    </div>
  );
}
