import React, { useState } from 'react';
import "./App.css";
import TopBar from "./Components/topbar/topbar";
import Hero from "./Components/landing_pages/hero";
import AboutUs from "./Components/about_us_page/aboutUs";
import WhoWeAre from "./Components/whoWeAre/whoWeAre";
import Feedback from "./Components/feedback/feedback";
import WhyUs from "./Components/whyUs/whyUs";
import Solutions_and_services from "./Components/solutions_and_services/solutions_and_service";
import Contact_us from "./Components/contact_us/contact_us";
import Footer from "./Components/Footer/Footer";
import Tie_ups from "./Components/tie_ups/tie_ups";
import Achievements from "./Components/achievements/achievements";
import Awards from "./Components/awards/awards";

import AboutUsPage from "./Components/extraPages/hcm/AboutUsPage";
import DevelopmentServices from "./Components/extraPages/developmentServices/developmentServices";
import Sap from "./Components/extraPages/sap/sap";
import AwardsFull from "./Components/extraPages/awards/awards_full";
import Intelligent from "./Components/extraPages/intelligent/intelligent";
import Kronos from "./Components/extraPages/kronos/kronos";
import Cwfm from "./Components/extraPages/cwfm/cwfm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./Components/extraPages/privacyPolicy/privacyPolicy";

import BookDemoModal from './Components/react_modal/reactModal'; // Adjust the import path if necessary

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage openModal={openModal} />} />
          <Route path="/aboutUs" element={<AboutUsPage />} />
          <Route path="/devServices" element={<DevelopmentServices />} />
          <Route path="/sap" element={<Sap />} />
          <Route path="/AwardsFull" element={<AwardsFull />} />
          <Route path="/intelligent" element={<Intelligent />} />
          <Route path="/kronos" element={<Kronos />} />
          <Route path="/cwfm" element={<Cwfm />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
      <BookDemoModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      />
    </div>
  );
}

const HomePage = ({ openModal }) => (
  <div>
    <TopBar />
    <Hero openModal={openModal} />
    <Feedback openModal={openModal} />
    {/* <Tie_ups /> */}
    {/* <Awards/> */}
    <AboutUs />
    {/* <Achievements /> */}
    <WhoWeAre openModal={openModal} />
    {/* <WhyUs /> */}
    <Solutions_and_services />
    <Contact_us />
    <Footer />
  </div>
);

export default App;
