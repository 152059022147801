import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import img1 from "../../../img/compliances.jpg";

export default function Sap() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <div className="segregateContainer">
        <div className="leftContent">
          <HcmData
            scrollTo={solutionsAndServicesRef}
            onClickButton={handleGoBack}
            isButton="Go Back"
            heading="Workmen Onboarding and
        Labor Compliances
        "
            text="VYN helps users to monitor Contractor and Workmen data on a real time basis through the online process. Workmen data is validated prior to onboarding using authorized government services
        "
          />

          <HcmData text="Additionally, VYN helps Principal Employers to reconcile and verify in a jiffy the two critical statutory compliances like PF & ESI of Contractors via our unique Compliance feature" />
        </div>
        <div className="imageRight">
          <img src={img1} alt="" />
        </div>
      </div>
      {/* <button onClick={handleGoBack} className="homeIconCont">
        <h3 style={{ marginTop: "-0rem" }}>Go Back</h3>
      </button> */}
      <div onClick={handleGoBack}>
        <i
          style={{
            fontSize: "30px",
            color: "#0077B6",
            marginLeft: "4rem",
            marginBottom: "3rem",
          }}
          class="fa-solid fa-circle-arrow-left"
        ></i>
      </div>
      <Contact_us />
      <Footer />
    </div>
  );
}
