export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-Links",
    toName: "Home",
    isExternal: true
  },
  {
    title: "About Us",
    url: "/aboutUs",
    cName: "nav-Links",
    toName: "AboutUs",
    isExternal: true,
  },
  // {
  //   title: "Resources",
  //   url: "/",
  //   cName: "nav-Links",
  //   toName: "WhoWeAre",
  // },
  // {
  //   title: "Why Us",
  //   url: "/",
  //   cName: "nav-Links",
  //   toName: "WhyUs",
  // },
  // {
  //   title: "Careers",
  //   url: "/",
  //   cName: "nav-Links",
  //   toName: "Solutions",
  // },
  {
    title: "Contact Us",
    url: "/",
    cName: "nav-Links",
    toName: "ContactUs",
    // isExternal: true
  },
];
