// Navbar.js
import React, { Component } from "react";
import "./topbar.css";
import webLogo from "../../img/wikiworks-logo-White-Tag.png";
import { MenuItems } from "./menuItems";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import BookDemoModal from "../react_modal/reactModal"; // Correct the import path if necessary

class Navbar extends Component {
  state = { clicked: true, modalIsOpen: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  handleMenuClick = () => {
    this.setState({ clicked: true });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    return (
      <>
        <nav className="navbar-Items">
          <h1 className="navbar-Logo">
            <img height={40} src={webLogo} alt="logo" />
          </h1>
          <div className="menu-Icons" onClick={this.handleClick}>
            <i
              className={
                this.state.clicked ? "fa-solid fa-bars" : "fa-solid fa-xmark"
              }
            ></i>
          </div>

          <ul
            className={
              this.state.clicked ? "navbar-Menu" : "navbar-Menu active"
            }
          >
            {MenuItems.map((item, index) => {
              return (
                <li key={index}>
                  {item.isExternal ? (
                    <RouterLink
                      to={item.url}
                      className={item.cName}
                      onClick={item.handleMenuClick}
                    >
                      {item.title}
                    </RouterLink>
                  ) : (
                    <ScrollLink
                      to={item.toName}
                      smooth={true}
                      spy={true}
                      activeClass="activeClass"
                      offset={-window.innerHeight / 10}
                    >
                      <a
                        className={item.cName}
                        href={item.url}
                        onClick={this.handleMenuClick}
                      >
                        {item.title}
                      </a>
                    </ScrollLink>
                  )}
                </li>
              );
            })}
            <li>
              <button className="award-button-b-g" onClick={this.openModal}>
                Book Demo
              </button>
            </li>
          </ul>
        </nav>
        <BookDemoModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
        />
      </>
    );
  }
}

export default Navbar;
