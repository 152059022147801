import "./hcm.css";
import React, { useEffect, useRef } from "react";

export default function HcmData(props) {
  const hcmContainerRef = useRef(null);

  useEffect(() => {
    if (hcmContainerRef.current && props.scrollTo) {
      hcmContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [props.scrollTo]);

  // Check if text3 and text4 props are provided
  const isSecondContainerOnly = props.text3 && props.text4;

  return (
    <div>
      {/* Conditionally render the first container if text3 and text4 are not the only props provided */}
      {!isSecondContainerOnly && (
        <div ref={hcmContainerRef} className="hcmContainer">
          <h1>{props.heading}</h1>
          <div className="row-cont">
            <p className="row-head">{props.text2}</p>
            <p>{props.text}</p>
          </div>
          {/* {props.isButton ? (
            <button onClick={props.onClickButton} className="homeIconCont">
              {props.isButton}
            </button>
          ) : (
            <div></div>
          )} */}
        </div>
      )}

      {/* Always render the second container, but it could be adjusted to conditionally render as well */}
      <div className={isSecondContainerOnly ? "hcmContainer2-only" : "hcmContainer2"}>
      <p className="row-head-3">{props.text5}</p>

        <div className="row-cont-2">
          <p className="bullet">{props.text3}</p>
          <p className="row-head-2">{props.text4}</p>
        </div>
      </div>
    </div>
  );
}
