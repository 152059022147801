import React from "react";
import "./tie_ups.css";
import l1 from "../../img/1.png";
import l2 from "../../img/2.png";
import l3 from "../../img/3.png";
import l4 from "../../img/4.png";
import l5 from "../../img/5.png";
import l6 from "../../img/6.png";
import l7 from "../../img/7.png";
import l8 from "../../img/8.png";
import l9 from "../../img/9.png";
import l10 from "../../img/image 16.png";
import feath from "../../img/Vector 1.png";
import l11 from "../../img/11.png";
import l12 from "../../img/12.png";
import l13 from "../../img/13.png";

function tie_ups() {
  return (
    <div className="tieUpMainCont">
      <div className="box">
        <div className="tie-up-header">
          <div className="norm-tie-up-text">Trusted by the</div>
          <div className="hori-space"></div>
          <div className="color-tie-up-text">Worlds leading </div>
          <div className="hori-space"></div>
          <div className="norm-tie-up-text">brands and agencies</div>
        </div>
        <div className="feather-image">
          <img src={feath} alt="" />
        </div>
        <div className="firstRowing">
          {/* <img className="firstImageStyle" src={l1} alt="" /> */}
          {/* <img className="firstImageStyle" src={l2} alt="" /> */}
          <img className="firstImageStyle" src={l3} alt="" />
          <img className="firstImageStyle" src={l10} alt="" />
          <img className="firstImageStyle" src={l13} alt="" />
          <img className="firstImageStyle" src={l7} alt="" />
          {/* <img className="firstImageStyle" src={l4} alt="" /> */}
          <img className="firstImageStyle" src={l5} alt="" />
        </div>
        {/* <div className="secondRowing"> */}
        {/* <img className="secondImageStyle" src={l6} alt="" /> */}

        {/* <img className="secondImageStyle" src={l8} alt="" /> */}
        {/* <img
            className="secondImageStyle"
            style={{
              marginTop: "8px",
            }}
            src={l9}
            alt=""
          /> */}
        {/* </div> */}
        {/* <div className="thirdRowing"> */}
        {/* <img className="thirdImageStyle" src={l11} alt="" />
          <img className="thirdImageStyle" src={l12} alt="" /> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default tie_ups;
