import React from "react";
import "./ourStory.css";
import pr2 from "../../img/Designer (8) 1.png";
import coreImg from "../../img/Frame 1592.png";
import pr3 from "../../img/Feature 2.png";
import pr4 from "../../img/Feature 3.png";
// import pr4 from "../../img/wikiworks logo 230725-01 2.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function ourStory() {
  return (
    <div className="whoWeAreMain-o">
      {/* <div className="who-we-are-main-head-o">Features</div> */}
      <div id="WhoWeAre" className="whoWeAreUp-o">
        <div className="whoWeAreRight-o">
          <h1 className="whoWeAreHeader-o">Our Story</h1>

          <div className="whoWeAreText-o">
            <p
              style={{
                wordWrap: "break-word", // Wrap long words to avoid overflow
                whiteSpace: "normal", // Allow text to wrap and break lines
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors{" "}
            </p>
          </div>
        </div>

        <div className="whoWeAreLeft-o">
          <img src={pr2} alt="pr2" />
        </div>
      </div>

      <div  className="who-we-are-main-head-o">
        A Message from CEO and Chairman
      </div>

      <div className="ceo-cards-wrap">
        <div className="ceo-indi-card">
          <div className="card-o">
            <div className="card-image-o"></div>
            <div className="card-content-o">
              <p className="quote-o">
                “It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using.”
              </p>
              <p className="author-o">Gopal Amin</p>
              <p className="position-o">Chairman, Wikiworks</p>
            </div>
          </div>
        </div>
        <div className="divider-o"></div>
        <div className="ceo-indi-card">
          <div className="card-o">
            <div className="card-image-o-2"></div>
            <div className="card-content-o">
              <p className="quote-o">
                “It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using.”
              </p>
              <p className="author-o">Hanuman Chittem</p>
              <p className="position-o">CEO, Wikiworks</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{ paddingBottom: "2rem" }} className="who-we-are-main-head-o">
        Our Leadership team{" "}
      </div>

      <div className="ceo-cards-wrap">
        <div class="card-container-zo">
          <div class="card-zo">
            <div class="image-placeholder-zo"></div>
            <div class="info-zo">
              <p class="name-zo">Name</p>
              <p class="designation-zo">Designation</p>
            </div>
          </div>
          <div class="card-zo">
            <div class="image-placeholder-zo"></div>
            <div class="info-zo">
              <p class="name-zo">Name</p>
              <p class="designation-zo">Designation</p>
            </div>
          </div>
          <div class="card-zo">
            <div class="image-placeholder-zo"></div>
            <div class="info-zo">
              <p class="name-zo">Name</p>
              <p class="designation-zo">Designation</p>
            </div>
          </div>
          <div class="card-zo">
            <div class="image-placeholder-zo"></div>
            <div class="info-zo">
              <p class="name-zo">Name</p>
              <p class="designation-zo">Designation</p>
            </div>
          </div>
        </div>
      </div> */}

      <div style={{ paddingBottom: "2rem" }} className="who-we-are-main-head-o">
        Our Core value
      </div>
      <div className="core-value-cont">
        <div className="core-value-up">
          <img className="core-img" src={coreImg} alt="" />
        </div>
        <div className="core-value-down">
          <div className="core-value-content">
            Guided by our unwavering commitment to putting customers at the
            heart of everything we do, we prioritize your needs above all else.
            Welcome to a community where the values of integrity, respect, and
            excellence converge to create unparalleled experiences tailored just
            for you.
          </div>
        </div>
      </div>

      {/* swiper */}
      {/* <div style={{ paddingBottom: "2rem" }} className="who-we-are-main-head-o">
      Employee spot light
      </div>
      <div className="swiper-main-cont">
        <div className="swiper-container-ko">
          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            navigation
            pagination={{ clickable: true }}
            className="mySwiper-ko"
          >
            <SwiperSlide className="swiper-slide-ko">
              <div className="card-ko">
                <div className="image-placeholder-ko"></div>
                <div className="info-ko">
                  <p className="description-ko">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters.
                  </p>
                  <p className="name-ko">Spoothi</p>
                  <p className="designation-ko">Software Testing</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide-ko">
              <div className="card-ko">
                <div className="image-placeholder-ko"></div>
                <div className="info-ko">
                  <p className="description-ko">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters.
                  </p>
                  <p className="name-ko">Spoothi</p>
                  <p className="designation-ko">Software Testing</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide-ko">
              <div className="card-ko">
                <div className="image-placeholder-ko"></div>
                <div className="info-ko">
                  <p className="description-ko">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters.
                  </p>
                  <p className="name-ko">Spoothi</p>
                  <p className="designation-ko">Software Testing</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide-ko">
              <div className="card-ko">
                <div className="image-placeholder-ko"></div>
                <div className="info-ko">
                  <p className="description-ko">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters.
                  </p>
                  <p className="name-ko">Spoothi</p>
                  <p className="designation-ko">Software Testing</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div> */}
    </div>
  );
}

export default ourStory;
