import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";

import "../hcm/hcm.css";
export default function Intelligent() {
  return (
    <div>
      {/* <TopBar /> */}
      <HcmData
        isButton="Go Back"
        heading="Intelligent Automation
        "
        text="WikiWorks provides Intelligent Automation services across Test Automation and RPA. The objective is to increase Employee Engagement & improve Productivity. We adopt a holistic approach towards application testing across mobile, web, desktop, embedded platforms and microservices. Our services allow you to define, build, implement and migrate test automation tools and automated test scripts."
      />

      <div className="normText">• Up-to 100% test coverage</div>
      <div className="normText">• Early defect detection</div>
      <div className="normText">• Tool-agnostic solutions</div>
      <div className="normText">• Reduced cycle time</div>
      <Contact_us />
      <Footer />
    </div>
  );
}
