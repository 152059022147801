import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";

export default function Kronos() {
  return (
    <div>
      {/* <TopBar /> */}
      <HcmData
        isButton="Go Back"
        heading="Services for UKG/Kronos
        "
        text="wikiworks has been providing full scale services for UKG/Kronos since 2013. With many delighted customers and 1000s of users, we have won awards from UKG along the way. UKG/Kronos offers one of the industry's most powerful Workforce Management tools to manage and engage your entire workforce.
        "
      />

      <HcmData text="wikiworks’ HR domain & UKG solution experts have proven experience to implement WFM solutions in an accelerated manner with minimum customizations leveraging industry specific best practices. We integrate seamlessly using Dell Boomi or WIM or SAP HANA Cloud with other 3rd party ERP systems." />
      <HcmData
        text="UKG’s WFM solutions are very differentiated - It fully automates Punch-to-Pay, with AI-powered recommendation including leave planning, alternate workman allocation, scheduling (to name a few), enabled with contactless systems, geo tracking based T&A etc., along with real-time & accurate operational visibility. It also integrates with multiple HCM & technology solutions (e.g., Oracle, Success Factors, Workday, Dell Boomi integration); All seamlessly experienced with 100% functionality on desktop, tablet or phone.
The solutions are effective across all categories of workforce – white/blue collar, on-roll/contract, be it in factory/corporate office/warehouse/showroom/field employees etc. UKG/Kronos is a Global Leader in Workforce Management, serving over half of the Fortune 1000. The solution is available in 13 languages, and is being used by 42+ million people across 118 countries."
      />
      <Contact_us />
      <Footer />
    </div>
  );
}
