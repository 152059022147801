import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import img1 from "../../../img/wiki_award.png";

export default function AwardsFull() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <div className="segregateContainer">
        <div className="leftContent">
          <HcmData
            scrollTo={solutionsAndServicesRef}
            onClickButton={handleGoBack}
            isButton="Go Back"
            heading="Key Highlights:"
            text2="Award Recognition : "
            text="WikiWorks's dedication to innovation and excellence in the field of contract workforce management has been acknowledged with the 'Innovation Partner of the Year' award.
        "
          />

          <HcmData
            text2="Strategic Partnership: "
            text="Our collaboration with UKG, a leader in time and attendance management, has resulted in a powerful ecosystem tailored to meet the unique needs of companies looking for contractual workforce management solutions.
 "
          />
          <HcmData
            text2="End-to-End Workforce Management:"
            text=" Together, WikiWorks and UKG offer a comprehensive solution empowering Companies to seamlessly manage their contractual workforce from start to finish.
 "
          />

          <HcmData text5="Benefits of WikiWorks solutions" />
          <HcmData
            text3="•"
            text4="Aadhaar verified onboarding in less than 5 minutes"
          />
          <HcmData
            text3="•"
            text4="Easy compliance management for PF and ESIC regulations"
          />
          <HcmData
            text3="•"
            text4="100% digitized solution, no need of paperwork"
          />
          <HcmData
            text3="•"
            text4="Extremely secure solution – Hosted on Google Cloud Platform, SOC2 Compliant, CERT-IN Certified
            "
          />
          <HcmData
            text3="•"
            text4="Seamless integration with UKG
            "
          />

          {/* <HcmData text="Benefits of WikiWorks solutions –
 "  text2="Benefits of WikiWorks solutions –
 " /> */}
        </div>
        <div className="imageRight-2">
          <img src={img1} alt="" />
        </div>
      </div>
      {/* <button onClick={handleGoBack} className="homeIconCont">
        <h3 style={{ marginTop: "-0rem" }}>Go Back</h3>
      </button> */}
      <div onClick={handleGoBack}>
        <i
          style={{
            fontSize: "30px",
            color: "#0077B6",
            marginLeft: "4rem",
            marginBottom: "3rem",
          }}
          class="fa-solid fa-circle-arrow-left"
        ></i>
      </div>
      <Contact_us />
      <Footer />
    </div>
  );
}
