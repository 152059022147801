import React from "react";
import "./feedback.css";
import pr2 from "../../img/Group 143.png";
import pr3 from "../../img/3.png";
import pr4 from "../../img/7.png";
import pr5 from "../../img/5.png";
import bl1 from "../../img/pr1.jpg";
import bl2 from "../../img/pd1.jpg";
import bl3 from "../../img/pd6.jpg";
import vid from "../../img/Wikiworks_27 06 2024.mp4";
// import pr4 from "../../img/wikiworks logo 230725-01 2.png";

function feedback({ openModal }) {
  return (
    <div className="feedback-main">
      <div className="vid-feeback-main">
        {/* <img src={pr2} alt="" className="vid-feedback-img"></img> */}
        {/* <iframe
          src="https://drive.google.com/file/d/1Jwv1NFyNOkEm6LfvMJy0kDkm0iQhuVjb/view?usp=drive_link"
          className="vid-feedback-img"
          allow="autoplay"
        ></iframe> */}
        <video
          autoPlay
          muted
          loop
          controls
          width="640"
          height="480"
          className="vid-feedback-img"
          poster={pr2}
        >
          <source src={vid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="vid-feedback-content-main">
          <div className="vid-feedback-content-header1">
            Embrace Innovation : Break free from{" "}
          </div>
          {/* <div className="vid-feedback-content-header2"></div> */}
          <div className="vid-feedback-content-header-color">
            Compliance violation
          </div>
          <div className="vid-feedback-content-sub-para">
            Check out how we take care of your compliance needs and other things
            required in managing contract labor.{" "}
          </div>
          <div className="vid-feedback-button-cont">
            <button onClick={openModal} className="award-button-f">
              Book Demo
            </button>
          </div>
        </div>
      </div>

      {/* <div className="feedback-main-header">Feedbacks that inspires trust</div>

      <div className="testi-main">
        <div className="testi-card">
          <img src={pr3} alt="" className="testi-img"></img>
          <div className="testi-content">
            Lorem ipsum is a placeholder text commonly used to demonstrate the
            visual form of a document or a typeface without relying on
            meaningful content. Lorem ipsum may be used as a placeholder before
            the final copy is available.
          </div>
          <div className="testi-name">Ajay</div>
          <div className="testi-desig">HCCB</div>
        </div>

        <div className="testi-card">
          <img src={pr4} alt="" className="testi-img"></img>
          <div className="testi-content">
            Lorem ipsum is a placeholder text commonly used to demonstrate the
            visual form of a document or a typeface without relying on
            meaningful content. Lorem ipsum may be used as a placeholder before
            the final copy is available.
          </div>
          <div className="testi-name">Ajay</div>
          <div className="testi-desig">MP Birla Group</div>
        </div>

        <div className="testi-card">
          <img src={pr5} alt="" className="testi-img"></img>
          <div className="testi-content">
            Lorem ipsum is a placeholder text commonly used to demonstrate the
            visual form of a document or a typeface without relying on
            meaningful content. Lorem ipsum may be used as a placeholder before
            the final copy is available.
          </div>
          <div className="testi-name">Ajay</div>
          <div className="testi-desig">Ultra Tech Cement</div>
        </div>
      </div> */}
      {/* <div className="feedback-main-header">Blogs</div>

      <div className="blog-main">
        <div className="blog-card">
          <img src={bl1} alt="" className="blog-img"></img>
          <div className="blog-content">
            <div className="blog-header">Title of the blog goes here</div>
            <div className="blog-sub-content">Lorem ipsum is a placeholder text commonly used to demonstrate the visual</div>
          </div>
        </div>
        <div className="blog-card">
          <img src={bl2} alt="" className="blog-img"></img>
          <div className="blog-content">
            <div className="blog-header">Title of the blog goes here</div>
            <div className="blog-sub-content">Lorem ipsum is a placeholder text commonly used to demonstrate the visual</div>
          </div>
        </div>
        <div className="blog-card">
          <img src={bl3} alt="" className="blog-img"></img>
          <div className="blog-content">
            <div className="blog-header">Title of the blog goes here</div>
            <div className="blog-sub-content">Lorem ipsum is a placeholder text commonly used to demonstrate the visual</div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default feedback;
