import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import img1 from "../../../img/cwfm.jpg";

export default function Cwfm() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}

      <div className="segregateContainer">
        <div className="leftContent">
          <HcmData
            scrollTo={solutionsAndServicesRef}
            onClickButton={handleGoBack}
            isButton="Go Back"
            heading="Contract Workforce Management"
          />
          <HcmData text="With the emergence of a large number of people willing to work part-time or in temporary positions, the effective inclusion and efficient management of the contract workforce, also called contingent workforce, in any organization is very critical. Businesses need applications that can help them address role-based staffing in a timely, cost-effective way and also meeting all compliance requirements." />

          <HcmData text="WikiWorks is a proven expert in Contract Workforce Management (CWFM) solutions with end-to-end services.        " />
          <HcmData text="While engaging with the contract workforce gives the flexibility to attract skilled expertise, address seasonal demands, or control labour costs, most companies are not ready for it from a geography- specific compliance standpoint. WikiWorks has a deep understanding of the products in the market and has consulted, implemented, supported such requirements for many delighted customers and users." />
          <HcmData
            text="WikiWorks also has a unique digital app for onboarding of contract workforce with real-time Aadhar based verification.
"
          />
        </div>
        <div className="imageRight">
          <img src={img1} alt="" />
        </div>
      </div>
      {/* <button onClick={handleGoBack} className="homeIconCont">
        <h3 style={{ marginTop: "-0rem" }}>Go Back</h3>
      </button> */}
      <div onClick={handleGoBack}>
        <i
          style={{
            fontSize: "30px",
            color: "#0077B6",
            marginLeft: "4rem",
            marginBottom: "3rem",
          }}
          class="fa-solid fa-circle-arrow-left"
        ></i>
      </div>
      <Contact_us />
      <Footer />
    </div>
  );
}
