import React from "react";
import "./aboutUs.css";
import pr2 from "../../img/sectors.jpg";
import ach1 from "../../img/Achivements 1.png";
import ach2 from "../../img/Achivements 2.png";
import ach3 from "../../img/Group 293.png";
import stat1 from "../../img/Group 131.png";
import stat2 from "../../img/Group 136.png";
import stat3 from "../../img/Group 137.png";
import stat4 from "../../img/Group 2409.png";
import TieUps from "../tie_ups/tie_ups"
// import pr4 from "../../img/wikiworks logo 230725-01 2.png";
// import pr5 from '../../img/pr5.jpg'
// import pr6 from '../../img/pr6.png'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import { Navigation, Pagination, Autoplay } from 'swiper'
// import 'swiper/css'
// import 'swiper/css/pagination'
// import 'swiper/css/navigation'
// import 'swiper/css/autoplay'

import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

const AboutUs = () => {
  const [ref, inView] = useInView({ threshold: 0.1 });

  const props = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(100%)",
    },
    config: { duration: 1000 },
  });

  const props2 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(100%)",
    },
    config: { duration: 1000 },
  });

  const props3 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(100%)",
    },
    config: { duration: 1000 },
  });
  return (
    <div
      id="AboutUs"
      className="aboutUsMain"
      //   style={{ display: "flex", flexDirection: "row" }}
    >
      <div className="aboutUsLeft">
        <div className="about-us-cont">
          <div className="about-us-header">About us</div>
          <div className="about-us-para">
            WikiWorks is a new-age technology company focused on transformation
            of Human Capital Management (HCM). Our leading products help
            customers to automate contract workforce management including
            compliance aspects.
          </div>
        </div>

        {/* <img src={pr2} alt="pr2" /> */}
      </div>
      <div ref={ref} className="aboutUsRight">
        {/* <h1 className="aboutUsHeader">ABOUT US</h1> */}
        <img className="about-us-img" src={ach1} alt="pr2" />
        <img className="about-us-img" src={ach2} alt="pr2" />
        <img className="about-us-img" src={ach3} alt="pr2" />

        {/* <div className="aboutUsText">
          <p
            style={{
              wordWrap: "break-word", // Wrap long words to avoid overflow
              whiteSpace: "normal", // Allow text to wrap and break lines
            }}
          >
            WikiWorks is a new-age technology company focused on transformation
            of <b>Human Capital Management </b>
            (HCM). We have deep expertise across{" "}
            <b>
              Retail, Manufacturing, Healthcare, Hospitality, Services and
              Distribution sectors
            </b>
            . Our leading products help customers to automate contract workforce
            management including compliance aspects.{" "}
          </p>
        </div> */}
      </div>
      <TieUps/>
      <div ref={ref} className="aboutUsDown">
        <img className="about-us-down-img" src={stat1} alt="pr2" />
        <img className="about-us-down-img" src={stat2} alt="pr2" />
        <img className="about-us-down-img" src={stat3} alt="pr2" />
        <img className="about-us-down-img" src={stat4} alt="pr2" />

        {/* <div className="stat-indi-cont">
          <div className="stat-num">&lt;5</div>
          <div className="stat-main-head">Minutes</div>
          <div className="stat-sub-para">
            To perform end to end onboarding operations
          </div>
        </div>
        <div className="stat-indi-cont">
          <div className="stat-num">10x</div>
          <div className="stat-main-head">Faster</div>
          <div className="stat-sub-para">Compliance process</div>
        </div>
        <div className="stat-indi-cont">
          <div className="stat-num">50%</div>
          <div className="stat-main-head">Increase in retention</div>
          <div className="stat-sub-para">
            Improvement in retention due to verified onboarding{" "}
          </div>
        </div>
        <div className="stat-indi-cont">
          <div className="stat-num">100%</div>
          <div className="stat-main-head">Satisfaction</div>
          <div className="stat-sub-para">Happy Clients</div>
        </div> */}
      </div>
    </div>
  );
};

export default AboutUs;
